import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import BingoHub from './bingo-hub'

export default {
  install () {
    const connection = new HubConnectionBuilder()
    .withUrl('https://bingo-admin.urk.fm/signalr-hubs/bingo')
    .configureLogging(LogLevel.Error)
    .build()

    let startedPromise = null
    function start () {
    startedPromise = connection
        .start()
        .catch(err => {
            console.error('Failed to connect with hub', err)
            return new Promise((resolve, reject) => 
            setTimeout(() => start().then(resolve).catch(reject), 5000))
        })
        .then(() => {
            BingoHub.$emit('ConnectionReady')
        })
        return startedPromise
    }
    connection.onclose(() => start())
    start()

    connection.on('NumberStateUpdated', (numberId, state) => {
        BingoHub.$emit('number-state-changed', { numberId, state })
    })

    connection.on('ActiveRoundUpdated', (data) => {
        BingoHub.$emit('active-round-updated', {data})
    })

    connection.on('RoundStateUpdated', (data) => {
        BingoHub.$emit('round-state-updated', {data})
    })
  }
}