<template>
  <div id="app">
    <div class="container-fluid background">
      <!--div class="row bovenachter"></div-->
      <div class="row bovenkant">
        <div class="redbackground"></div>
        <div class="col-lg-1"></div>
        <div class="col-lg-6 col-sm-6">
          <h2>{{settings.WebAppTitle}}</h2>
          <h1>{{settings.WebAppSubTitle}}</h1>
        </div>
        <div class="col-lg-4 col-sm-6">
          <img src="./assets/logo2.png" height="60%" class="pull-right" />
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
    <b-container>
      <b-alert variant="danger" :show="activeRound.bingo"><p v-html="settings.WebAppBingoInstruction"></p> Houd je bingo boekje bij de hand voor je serienummer</b-alert>
      <b-row v-if="isEventActive">
        <b-col md="4">
          <b-card>
            <div>
              Bingo: {{ event.name }}
              <br />
              Begint om: {{ eventStartTime }}
              <br />
              {{ event.description }}
            </div>
            <hr />
            <div v-if="!noBingo">
              Kleur: {{ bingoColor }}
              <br>
              We spelen nu voor: {{ bingoRoundState }}
              <hr>
              Prijzen: {{ activeRound.prices }}
            </div>
          </b-card>
          <!--b-card v-if="!noBingo && settings.WebAppBingoInstruction">
            <p v-html="settings.WebAppBingoInstruction"></p>
          </b-card-->
          <b-card v-if="!noBingo">
            <h5>Laatste 5 nummers</h5>
            <div
              v-for="(number, key) in lastFive"
              :key="key"
              class="lastBingoNumber"
            >
              <b-button
                :variant="number.state ? 'danger' : 'default'"
                disabled
                >{{ number.bingoNumber }}</b-button
              >
            </div>
          </b-card>
        </b-col>
        <b-col md="8">
          <b-card v-if="noBingo">
            <p>Er word op dit moment geen bingo gespeeld</p>
          </b-card>
          <b-card v-else class="bingo-card">
            <div
              v-for="(number, key) in activeRound.numbers"
              :key="key"
              class="bingoNumber"
            >
              <b-button
                :variant="number.state ? 'danger' : 'default'"
                disabled
                class="d-none d-lg-block"
                >{{ number.bingoNumber }}</b-button
              >
              <b-button
                :variant="number.state ? 'danger' : 'default'"
                disabled
                size="sm"
                class="d-lg-none"
                >{{ number.bingoNumber }}</b-button
              >
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col md="12">
          <b-card title="Er word momenteel geen bingo gespeeld">
            <p v-if="event">
              De eerstvolgend bingo is op: {{ eventDateFormatted}}
            </p>
            <p v-else>
              Het is nog niet bekend wanneer de eerstvolgende bingo word
              gespeeld
            </p>
          </b-card>
        </b-col>
      </b-row>
      <hr />
      <b-row v-if="isEventActive">
        <b-col md="4" offset-lg="2" v-if="event.audioUrl">
          <b-card title="Livestream">
            <audio :src="event.audioUrl" controls width="100%"></audio>
          </b-card>
        </b-col>
        <b-col md="4" v-if="event.videoUrl">
          <b-card>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                :src="event.videoUrl"
                frameborder="0"
                allowfullscreen="true"
                scrolling="no"
                height="378"
                width="620"
              ></iframe>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
import axios from "axios";
import Vue from "vue";
import { DateTime } from "luxon";
import BingoHub from "./plugins/bingo-hub";
const camelcaseKeys = require('camelcase-keys');

const apiBaseUrl = "https://bingo-admin.urk.fm/api/";
DateTime.local().setLocale('nl');
//const apiBaseUrl = 'https://localhost:44382/api/';


export default {
  name: "App",
  components: {},
  data: function () {
    return {
      loading: false,
      noBingo: true,
      activeRound: {},
      event: {},
      isEventActive: false,
      settings: {},
      lastNumbers: []
    }
  },
  methods: {
    loadEvent() {
      axios
        .get("app/bingo-event/upcoming", {
          baseURL: apiBaseUrl,
          headers: { __tenant: Vue.getConfig("tenantId") },
        })
        .then((response) => {
          if (response.status == 200) {
            this.event = response.data;
            var dt = DateTime.fromISO(response.data.dateTime)
            if (dt.startOf("day").toMillis() === DateTime.local().startOf("day").toMillis()) {
              this.isEventActive = true
              this.loadActiveRound()
            }
          }
        });
    },
    loadSettings() {
      axios
        .get("abp/application-configuration", {
          baseURL: apiBaseUrl,
          headers: { __tenant: Vue.getConfig("tenantId") },
        })
        .then((response) => {
          this.$set(this.settings, 'WebAppTitle', response.data.setting.values["Bingo.WebAppTitle"])
          this.$set(this.settings, 'WebAppSubTitle', response.data.setting.values["Bingo.WebAppSubTitle"])
          this.$set(this.settings, 'WebAppBingoInstruction', response.data.setting.values["Bingo.WebAppBingoInstruction"])

        });
    },
    loadActiveRound() {
      axios
        .get("app/bingo-round/" + this.event.id + "/active", {
          baseURL: apiBaseUrl,
          headers: { __tenant: Vue.getConfig("tenantId") },
        })
        .then((response) => {
          if (response.status == 200) {
            response.data.numbers.sort((a, b) => a.bingoNumber - b.bingoNumber);
            this.activeRound = response.data;
            this.noBingo = false;
            this.lastNumbers = [];
          }
        })
        .catch((error) => {
          console.error(error)
        });
    },
    numberStateChanged({ numberId, state }) {
      var number = this.activeRound.numbers.find((s) => s.id === numberId);
      var updatedNumber = Object.assign({}, number);
      updatedNumber.state = state;
      this.$set(
        this.activeRound.numbers,
        this.activeRound.numbers.indexOf(number),
        updatedNumber
      );

      if (state && this.lastNumbers.find((s) => s.id === numberId) == null) {
        this.lastNumbers.push({...updatedNumber})
      } else {
        var inQueue = this.lastNumbers.find((s) => s.id === numberId);
        if (inQueue != null) {
          this.$delete(this.lastNumbers, this.lastNumbers.indexOf(inQueue))
        }
      }
    },
    updateRoundState(message){
      var data = JSON.parse(message.data);
      if (this.activeRound.id == data.Id) {
        this.activeRound.roundState = data.RoundState
        this.activeRound.bingo = data.Bingo
      }
    },
    updateActiveRound(message){
      try {
        
      var data = null;
      try {
          // Parse a JSON
          data = JSON.parse(message.data); 
      } catch (e) {
          // You can read e for more info
          // Let's assume the error is that we already have parsed the payload
          // So just return that
          data = message.data;
      }
      var camel = camelcaseKeys(data);
      camel.numbers = camelcaseKeys(data.Numbers);
      camel.numbers.sort((a, b) => a.bingoNumber - b.bingoNumber);
      this.noBingo = false;
      this.lastNumbers = [];
      this.activeRound = camel;
      } catch (error) {
        console.error("error loading active round, try legacy way");
        this.loadActiveRound();
      }
      
    },
    registerSignalRHandlers() {
      BingoHub.$on("number-state-changed", this.numberStateChanged);
      BingoHub.$on("active-round-updated", this.updateActiveRound);
      BingoHub.$on("round-state-updated", this.updateRoundState);
    },
  },
  computed: {
    bingoColor() {
      if (this.activeRound == null) return "";
      var color = "";
      switch (this.activeRound.color) {
        case 0:
          color = "Wit";
          break;
        case 1:
          color = "Roze";
          break;
        case 2:
          color = "Blauw";
          break;
        case 3:
          color = "Groen";
          break;
        case 4:
          color = "Crème";
          break;
        case 5:
          color = "Geel";
          break;

        default:
          color = "";
          break;
      }
      return color;
    },
    bingoRoundState() {
      if (this.activeRound == null) return "";
      var state = "";
      switch (this.activeRound.roundState) {
        case 10:
          state = "1 lijn vol";
          break;
        case 20:
          state = "2 lijnen vol";
          break;
        case 30:
          state = "volle kaart";
          break;
        default:
          state = "";
          break;
      }
      return state;
    },
    lastFive(){
      return this.lastNumbers.slice(-5)
    },
    eventStartTime() {
      if (this.event) {
        var dt = DateTime.fromISO(this.event.dateTime) 
        return dt.toLocaleString(DateTime.TIME_SIMPLE)
      }
      return null;
    },
    eventDateFormatted() {
      if (this.event) {
        var dt = DateTime.fromISO(this.event.dateTime)
        //return  dt.toLocaleString(DateTime.DATE_HUGE)
        return this.event.dateTime ? "Nog niet bekend" : dt.toLocaleString(DateTime.DATE_HUGE)
      }
      return null;
    },
  },
  created() {
    this.loadEvent();
    this.loadSettings();
  },
  mounted() {
    BingoHub.$on("ConnectionReady", this.registerSignalRHandlers);
  },
  beforeDestroy() {
    // Make sure to cleanup SignalR event handlers when removing the component
    BingoHub.$off("number-state-changed", this.numberStateChanged);
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&display=swap");

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.bingo-card .card-body {
  padding: 0.2rem !important;
}

.bingoNumber {
  text-align: center;
  padding: 10px;
  width: 10%;
  float: left;
  display: block;
  line-height: 1.42857143;
}

.lastBingoNumber {
  text-align: center;
  padding: 10px;
  width: 20%;
  float: left;
  display: block;
  line-height: 1.42857143;
}
.btn.disabled,
.btn:disabled {
  opacity: 1;
}

body {
  font-family: Quicksand, sans-serif;
  background: url("assets/bg-white.png");
  background-repeat: repeat;
}

.redbackground {
  width: 100%;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("assets/bg-red.png");
  background-repeat: repeat;
}

.bovenkant {
  position: relative;
  margin-bottom: -40px;
}

.bovenkant h2 {
  font-size: 40px;
  color: #fff;
}

.bovenkant h1 {
  font-size: 80px;
  color: #fff;
}
</style>
