import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'

//import axios from './plugins/axios'
//import VueAxios from 'vue-axios'
//import bingoHub from './plugins/bingo-hub'
import connection from './plugins/signalr'
import config from './plugins/env-configuration'

Vue.config.productionTip = false
Vue.use(connection)
Vue.use(config)
new Vue({
  render: h => h(App),
}).$mount('#app')


//Vue.use(VueAxios, axios)
//Vue.use(axios)
