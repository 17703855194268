import dotenv from 'dotenv';
import Vue from 'vue';

dotenv.config();

export default {
  install() {
    function config() {
      return {
        tenantId: '$VUE_APP_TENANT_ID',
      };
    }
    Vue.getConfig = function (name) {
      if (!(name in config())) {
        return;
      }

      const value = config()[name];
      if (!value) {
        return;
      }
      if (value.startsWith('$VUE_APP_')) {
        const envName = value.substr(1);
        const envValue = process.env[envName];
        if (envValue) {
          return envValue;
        } else {
          return
        }
      } else {
        return value;
      }
    }

    Vue.mixin({
      methods: {
        getConfigValue(name) {
          if (!(name in config())) {
            return;
          }

          const value = config()[name];
          if (!value) {
            return;
          }
          if (value.startsWith('$VUE_APP_')) {
            const envName = value.substr(1);
            const envValue = process.env[envName];
            if (envValue) {
              return envValue;
            } else {
              return
            }
          } else {
            return value;
          }
        }
      }
    })

    //document.cookie = '__tenant=' + value('tenantId')
  }
}